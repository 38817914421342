import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Grid,
  useMediaQuery,
  tableCellClasses,
  Modal,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import HorizontalLinearStepper from "../../components/Stepper/HorizontalLinearStepper";
import ModalImgEvidences from "../../components/Modal/ModalImgEvidences";
import ModalPDFEvidences from "../../components/Modal/ModalPDFEvidence";
import { SalesB2CAPI } from "../../api/SalesB2C";
import { useMemo } from "react";
import {
  LocalShippingRounded,
  WarehouseRounded,
  WhereToVoteRounded,
} from "@mui/icons-material";
import { MoreInfoButton } from "../../components/Buttons/MoreInfoButton";
import HorizontalLinearThreeSteper from "../../components/Stepper/HorizontalLinearThreeSteps";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f3f3f3",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  borderRadius: 5,
  height: "100%",
  // minHeight: 270,
  // display: "flex",
}));

export const DetailSales = ({ idModal }) => {
  const phone = useMediaQuery("(max-width:480px)");
  const location = useLocation();

  const [articles, setArticles] = useState([]);
  const [evidences, setEvidences] = useState({});
  const [cancelationInfo, setCancelationInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(0);
  const [activeStep, setActiveStep] = useState(null);
  const [images, setImages] = useState([]);
  const [pdf, setPdf] = useState("");
  const [openPdf, setOpenPdf] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [marketPlace, setMarketPlace] = useState(false);
  const [detailsOrogin, setDetailsOrigin] = useState("");
  const [order, setOrder] = useState({
    codigo_pedido: "",
    monto_pedido: "",
    canal: "",
    fecha_creacion: "",
    almacen_asignado: "",
    nombre_cliente: "",
    apellido_cliente: "",
    email_cliente: "",
    telefono_cliente: "",
    direccion: "",
  });
  const statusRoute = [
    { id: 1, status: "Listo para entrega" },
    { id: 2, status: "Asignado" },
    { id: 3, status: "En camino" },
    { id: 4, status: "Finalizado" },
    { id: 5, status: "Otro" },
  ];

  const trakingCards = [
    {
      title: "Sucursal Origen",
      auxInfo1: order.almacen_asignado,
      auxInfo2: "",
      icon: (
        <WarehouseRounded
          sx={{ fontSize: 60 }}
          className={
              status === 6
              ? "ds-ref-font"
              :activeStep > 0
              ? "ds-yellow-font"
              : "inactive-font"
          }
        />
      ),
    },
    {
      title: "Última milla",
      auxInfo1: evidences ? order.last_mille : "Sin datos de rastreo",
      auxInfo2: evidences ? (
        <>
          {evidences.uuid ? (
            <>
              <p className="m-0">
                <b>
                  Uuid:
                  <MoreInfoButton title="Identificador único de tu delivery." />
                </b>{" "}
                {evidences.uuid}
              </p>
              <p className="m-0">
                <b>
                  Pin:
                  <MoreInfoButton title="Se deberá proporcionar este pin code al repartidor al momento de la entrega." />
                </b>{" "}
                {evidences.pin_code}
              </p>
            </>
          ) : (
            evidences.numero_guia &&
            evidences.url_etiquetado && (
              <p className="m-0">
                Guía:
                <span
                  className="cursor-pointer ds-blue-font"
                  onClick={() => {
                    handlePdf(evidences.url_etiquetado);
                  }}
                >
                  {evidences.numero_guia}
                </span>
              </p>
            )
          )}
          {evidences.url_rastreo && (
            <a
              href={evidences.url_rastreo}
              target="_blank"
              className="ds-blue-font" rel="noreferrer"
            >
              Url de rastreo
            </a>
          )}
        </>
      ) : (
        ""
      ),
      icon: (
        <LocalShippingRounded
          sx={{ fontSize: 60 }}
          className={
            activeStep === 2
              ? "ds-blue-font"
              : status === 6
              ? "ds-ref-font"
              : activeStep > 0
              ? "ds-yellow-font"
              : "inactive-font"
          }
        />
      ),
    },
    {
      title: "Destino",
      auxInfo1: `${order.nombre_cliente} ${order.apellido_cliente}`,

      auxInfo2: evidences ? (
        evidences.url_evidences ? (
          <p
            className="cursor-pointer ds-blue-font"
            onClick={() => {
              handleImg( typeof evidences.url_evidences == "string" ? new Array(evidences.url_evidences) :  evidences.url_evidences.urls )}}
          >
            Evidencias
          </p>
        ) : (
          ""
        )
      ) : (
        ""
      ),
      icon: (
        <WhereToVoteRounded
          sx={{ fontSize: 60 }}
          className={activeStep === 4 ? "ds-yellow-font" : status === 6 ? "ds-ref-font" : "inactive-font"}
        />
      ),
    },
  ];


  const trakingCardsMarketPlace = [
    
    {
      title: "Generado",
      auxInfo1: evidences ? order.last_mille : detailsOrogin ? detailsOrogin : "Sin datos de rastreo",
      auxInfo2: evidences ? (
        <>
          {evidences.uuid ? (
            <>
              <p className="m-0">
                <b>
                  Uuid:
                  <MoreInfoButton title="Identificador único de tu delivery." />
                </b>{" "}
                {evidences.uuid}
              </p>
              <p className="m-0">
                <b>
                  Pin:
                  <MoreInfoButton title="Se deberá proporcionar este pin code al repartidor al momento de la entrega." />
                </b>{" "}
                {evidences.pin_code}
              </p>
            </>
          ) : (
            evidences.numero_guia &&
            evidences.url_etiquetado && (
              <p className="m-0">
                Guía:
                <span
                  className="cursor-pointer ds-blue-font"
                  onClick={() => {
                    handlePdf(evidences.url_etiquetado);
                  }}
                >
                  {evidences.numero_guia}
                </span>
              </p>
            )
          )}
          {evidences.url_rastreo && (
            <a
              href={evidences.url_rastreo}
              target="_blank"
              className="ds-blue-font" rel="noreferrer"
            >
              Url de rastreo
            </a>
          )}
        </>
      ) : (
        ""
      ),
      icon: (
        <LocalShippingRounded
          sx={{ fontSize: 60 }}
          className={
            activeStep === 2
              ? "ds-blue-font"
              : status === 6
              ? "ds-ref-font"
              : activeStep > 0
              ? "ds-yellow-font"
              : "inactive-font"
          }
        />
      ),
    },
    {
      title: "Finalizado",
      auxInfo1: `${order.nombre_cliente} ${order.apellido_cliente}`,

      auxInfo2: evidences ? (
        evidences.url_evidences ? (
          <p
            className="cursor-pointer ds-blue-font"
            onClick={() => {
              handleImg( typeof evidences.url_evidences == "string" ? new Array(evidences.url_evidences) :  evidences.url_evidences.urls )}}
          >
            Evidencias
          </p>
        ) : (
          ""
        )
      ) : (
        ""
      ),
      icon: (
        <WhereToVoteRounded
  
          sx={{ fontSize: 60 }}
          // className={activeStep === 4 ? "ds-yellow-font" : "inactive-font" || activeStep === 2 ? "ds-blue-font": "inactive-font"}
          className={activeStep === 4 ? "ds-yellow-font" : activeStep === 1 ? "ds-blue-font": status === 6 ? "ds-ref-font" : "inactive-font"}
        />
      ),
    },
  ];

  const handlePdf = (pdf) => {
    setOpenPdf(true);
    setPdf(pdf);
  };
  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  const handleImg = (imgData) => {
    setOpenImg(true);
    setImages(imgData);
  };
  const handleClose = () => {
    setOpenImg(false);
  };

  useEffect(() => {
    if(location.state?.id){
      GetSales();
    }
  }, []);

  //APIS
  const salesB2CAPI = useMemo(() => new SalesB2CAPI(), []);

  const GetSales = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append(
      "sale",
      location.state !== undefined ? location.state.id : idModal
    );

    const response = await salesB2CAPI.getSaleEP(data);
    if (response.status === 200) {
      const statusId = response.data.evidences?.status_id;
        if (statusId !== undefined) {
          setStatus(statusId); 
          if (statusId === 4) {
            setActiveStep(4);
          } else if (statusId === 6) {
            setActiveStep(null);
          } else {
            setActiveStep(statusId - 1);
          }
      }else{
        //setActiveStep(4)
        setMarketPlace(true)
        if(response.data.details.estatus_envio === "4" || response.data.details.estatus_envio === "7"){
          setActiveStep(4)
        }else{
          if(response.data.details.estatus_envio === "1"){
            setActiveStep(1)
          }else{
            if(response.data.details.estatus_envio === "6"){
              setActiveStep(null)
              setStatus(6)
            }else{
              setActiveStep(null)
            }
          }
        }
      }
      //setActiveStep(1)
      
      setArticles(response.data.articles);
      setEvidences(response.data.evidences);
      setDetailsOrigin(response.data.details.almacen_asignado)
      setCancelationInfo(response.data.cancelation)
      setOrder(response.data.details);
      setIsLoading(false);
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <div className="w-100 px-3 mt-3 ">
        <Grid
          container
          spacing={2}
          direction="row"
          columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}
        >
          {" "}
          {/*---Contenedor padre---*/}
          <Grid item xs={6} sm={6} md={4}>
            {" "}
            {/*---Ocupara mitad de pantalla---*/}
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ height: "100%" }}>
              {/*---Altura de 100 para que ocupe verticalmente---*/}
              <Item>
                <p
                  className={`font-weight-bold text-center ${
                    phone ? "h6" : "h5"
                  }`}
                >
                  Detalles de la venta
                </p>
                <div className={`mt-2`}>
                  {isLoading || location.state === undefined ? (
                    <>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </>
                  ) : (
                    <>
                      {cancelationInfo && 
                        <div>
                          <p className={`font-weight-bold ds-ref-font m-0 h6`}>Pedido cancelado</p>
                          <p className={`m-0`}>{cancelationInfo.fecha_actualizacion}</p>
                          <p className={`m-0`}>{cancelationInfo.comentarios}</p>
                          <hr className="my-2"/>
                        </div>
                      }
                      <div className={status === 6 && "inactive-font"}>
                        <p className="m-1">
                          Código de pedido:{" "}
                          <span className="font-weight-bold">
                            {order.codigo_pedido}{" "}
                          </span>{" "}
                        </p>
                        <p className="m-1">
                          Monto:{" "}
                          <span className="font-weight-bold">
                            ${order.monto_pedido} {order.tipo_moneda}
                          </span>{" "}
                        </p>
                        <p className="m-1">
                          Canal:{" "}
                          <span className="font-weight-bold">
                            {order.eccommerce}{" "}
                          </span>{" "}
                        </p>
                        <p className="m-1">
                          Fecha:{" "}
                          <span className="font-weight-bold">
                            {order.fecha_creacion}{" "}
                          </span>{" "}
                        </p>
                        <p className="m-1">
                          Cliente:{" "}
                          <span className="font-weight-bold">
                            {order.nombre_cliente} {order.apellido_cliente}
                          </span>{" "}
                        </p>
                        <p className="m-1">
                          Dirección de entrega:{" "}
                          <span className="font-weight-bold">
                            {order.direccion}
                          </span>{" "}
                        </p>
                        <p className="m-1">
                          Teléfono:{" "}
                          <span className="font-weight-bold">
                            {order.telefono_cliente}{" "}
                          </span>{" "}
                        </p>
                        <p className="m-1">
                          Email:{" "}
                          <span className="font-weight-bold">
                            {order.email_cliente}{" "}
                          </span>{" "}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={8}>
            <Grid item xs={12} sx={{ height: "100%" }}>
              <Item className="w-100" sx={{ minHeight: 220 }}>
                <div className="w-100 d-flex flex-column align-items-center">
                  <p className={`font-weight-bold ${phone ? "h6" : "h5"}`}>
                    Tracking de envío
                  </p>
                </div>
                <div className="mt-2">
                  {isLoading ? (
                    <div className="d-flex flex-column align-items-center">
                      <div className="d-flex justify-content-around mb-3 w-100">
                        <Skeleton
                          variant="rectangular"
                          width="25%"
                          height={100}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="25%"
                          height={100}
                        />
                        <Skeleton
                          variant="rectangular"
                          width="25%"
                          height={100}
                        />
                      </div>
                      <Skeleton variant="text" width={"90%"} />
                    </div>
                  ) : (
                    <>
                      {marketPlace ?(
                      <div>
                        <div className={`d-flex justify-content-between ${status === 6 && "ds-ref-font"}`}>
                          {trakingCardsMarketPlace.map((el, i) => (
                            <div
                              className="d-flex flex-column align-items-center justify-content-between w-30 text-center"
                              key={i}
                            >
                              {" "}
                              <div>
                                <div className={`${ status !== 6 ? "ds-blue-font" : "ds-ref-font"} font-weight-bold`}>
                                  {el.title}
                                </div>
                                <div className="">{el.auxInfo1}</div>
                                <div className="xs-font">{el.auxInfo2}</div>
                              </div>
                              {el.icon}
                            </div>
                          ))}
                        </div>
                        {
                          status === 6 && <p className="ds-ref-font text-center">Pedido Cancelado</p>
                        }
                        <HorizontalLinearThreeSteper activeStep={activeStep} status={status}/>
                      </div>) : (
                        <div>
                          <div className={`d-flex justify-content-between ${status === 6 && "inactive-font"}`}>
                          {trakingCards.map((el, i) => (
                            <div
                              className="d-flex flex-column align-items-center justify-content-between w-30 text-center"
                              key={i}
                            >
                              {" "}
                              <div>
                                <div className={`${ status !== 6 ? "ds-blue-font" : "inactive-font"} font-weight-bold`}>
                                  {el.title}
                                </div>
                                <div className="">{el.auxInfo1}</div>
                                <div className="xs-font">{el.auxInfo2}</div>
                              </div>
                              {el.icon}
                            </div>
                          ))}
                          
                        </div>
                        {
                          status === 6 && <p className="ds-ref-font text-center">Pedido Cancelado</p>
                        }
                        <HorizontalLinearStepper activeStep={activeStep} status={status}/>
                      </div>
                      )}
                      
                    </>
                  )}
                </div>
              </Item>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Paper className="px-2" sx={{ width: "100%" }}>
                <div className="pt-2 w-100 d-flex justify-content-center">
                  <p className={`font-weight-bold ml-2 ${phone ? "h6" : "h5"}`}>
                    Artículos
                  </p>
                </div>

                <TableContainer
                  sx={{ maxHeight: "41vh" }}
                  // sx={{ maxHeight: height - (74 + 90) }}
                >
                  <Table aria-label="sticky table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">SKU</StyledTableCell>
                        <StyledTableCell align="center">
                          Producto
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Cantidad
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span style={{ whiteSpace: "nowrap" }}>
                            Precio por unidad
                          </span>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell>
                            <Skeleton variant="text" />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton variant="text" />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton variant="text" />
                          </TableCell>
                          <TableCell align="center">
                            <Skeleton variant="text" />
                          </TableCell>
                        </TableRow>
                      ) : (
                        articles.map((item, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              key={index}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {item.sku}
                              </TableCell>
                              <TableCell align="center">
                                {item.descripcion}
                              </TableCell>
                              <TableCell align="center">
                                {item.quantity}
                              </TableCell>
                              <TableCell align="center">
                                {item.unitPrice}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>

      <Modal open={openImg} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalImgEvidences images={images} handleClose={handleClose} />
        </div>
      </Modal>

      <Modal open={openPdf} onClose={() => console.log("Cerrando")}>
        <div>
          <ModalPDFEvidences pdf={pdf} handleClosePdf={handleClosePdf} />
        </div>
      </Modal>
    </>
  );
};
