import React, { useRef, useState, useEffect } from "react";

import {
  Grid,
  useMediaQuery,
  Modal,
  Tooltip,
  IconButton,
  Badge,
  Popover,
} from "@mui/material";

import { EntriesGraph } from "../../components/Graphs/EntriesGraph";
import { LessExistancesGraph } from "../../components/Graphs/LessExistancesGraph";
import { MoreExistancesGraph } from "../../components/Graphs/MoreExistancesGraph";
import { SalesGraph } from "../../components/Graphs/SalesGraph";
import { TopSalesGraph } from "../../components/Graphs/TopSalesGraph";
import { DownloadButton } from "../../components/Buttons/DownloadButton";

import FilterIcon from "../../assets/SVGIcons/Filter.svg";

import {
  getDateCleaned,
  getDayjsFormatted,
} from "../../helpers/getDateCleaned";
import { ModalFilters } from "../../components/Modal/ModalFilters";
import {
  graphEntriesHeaders,
  graphLessHeaders,
  graphMoreHeaders,
  graphSalesHeaders,
  graphTopSalesHeaders,
} from "../../components/Excel/headersGraphs";
import { useMemo } from "react";
import { GraphsAPI } from "../../api/Graphs";
import { AdminAPI } from "../../api/Admin";
import { theme } from "../../utils/theme";

const styles = [
  [
    ["Entradas", "Cantidad", { role: "style" }],
    ["Por validar", 5, "#58BDDB"],
    ["Validadas", 10, "#7CB963"],
    ["Incompletas", 2, "#EE8C26"],
    ["Canceladas", 3, "#9B68A6"],
  ],
];

const dummyDataTopSales = [
  ["x", "y"],
  ["1", 5],
  ["2", 4],
  ["3", 3],
  ["4", 2],
  ["5", 1],
];

const dummyDataEntries = [
  [
    ["Entradas", "Cantidad"],
    ["Sin Entradas", 1],
  ],
  [
    ["Entradas", "Cantidad", { role: "style" }],
    ["Sin Entradas", 1, "#9B68A6"],
  ],
];

const dummyDataMore = [
  ["x", "y"],
  ["1", 5],
  ["2", 4],
  ["3", 3],
  ["4", 2],
  ["5", 1],
];

const dummyDataLess = [
  ["x", "y"],
  ["1", 1],
  ["2", 2],
  ["3", 3],
  ["4", 4],
  ["5", 5],
];

const dummyExcelEntries = [
  { type: "Por Validar", total: "0" },
  { type: "Validados", total: "0" },
  { type: "Cancelados", total: "0" },
];

const dummyExcelMore = [
  { product: "Sin datos", description: "Sin productos", stock: "0" },
];

const dummyExcelLess = [
  { product: "Sin datos", description: "Sin productos", stock: "0" },
];

const dummyExcelSales = [{ date: "Sin datos", amount: "0" }];

const dummyTopExcel = [{ decripcion: "Sin datos", sales: "0", total: "0" }];

export const Dashboard = () => {
  const phone = useMediaQuery("(max-width:480px)");
  const htmlElement = useRef(null);

  //DATA STATES
  //------------------------------------------------

  const [dataEntries, setDataEntries] = useState([]);
  const [dataMoreInventory, setDataMoreInventory] = useState([]);
  const [dataLessInventory, setDataLessInventory] = useState([]);
  const [dataMoreFullInventory, setDataMoreFullInventory] = useState([]);
  const [dataLessFullInventory, setDataLessFullInventory] = useState([]);
  const [dataSales, setDataSales] = useState([]);
  const [dataTopSales, setDataTopSales] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);

  //------------------------------------------------

  //ERRORES
  //------------------------------------------------

  const [errors, setErrors] = useState({
    error: false,
    errorNullStart: false,
    errorNullEnd: false,
  });

  //------------------------------------------------

  //MODAL STATES
  //------------------------------------------------

  const [openFilter, setOpenFilter] = useState(false);
  const [invisible, setInvisible] = useState(false);

  //------------------------------------------------

  //LOADERS STATES
  //------------------------------------------------

  const [loaderEntries, setLoaderEntries] = useState(true);
  const [loaderMore, setLoaderMore] = useState(true);
  const [loaderLess, setLoaderLess] = useState(true);
  const [loaderTop, setLoaderTop] = useState(true);
  const [loaderSales, setLoaderSales] = useState(true);

  //------------------------------------------------

  //FILTERS STATES
  //------------------------------------------------

  const [client, setClient] = useState("Todos");
  const [clients, setClients] = useState([]);

  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  const [startFilterDate, setStartFilterDate] = useState(firstDay);
  const [endFilterDate, setEndFilterDate] = useState(today);

  const [prevData, setPrevData] = useState({
    startFilterDate: firstDay,
    endFilterDate: today,
    client: "Todos",
  });

  //------------------------------------------------

  //EFFECTS
  //------------------------------------------------

  useEffect(() => {
    GetClients();
    RequestData();
  }, []);

  //------------------------------------------------

  //EXCEL STATES AND DATA
  //------------------------------------------------

  const [excelEntries, setExcelEntries] = useState([]);
  const [excelSales, setExcelSales] = useState([]);
  const [excelMore, setExcelMore] = useState([]);
  const [excelLess, setExcelLess] = useState([]);
  const [excelTop, setExcelTop] = useState([]);

  const info = [
    { name: "Envíos", data: excelEntries, headers: graphEntriesHeaders },
    {
      name: "Artículos con más Stock",
      data: excelMore,
      headers: graphMoreHeaders,
    },
    {
      name: "Artículos con menos Stock",
      data: excelLess,
      headers: graphLessHeaders,
    },
    { name: "Ventas", data: excelSales, headers: graphSalesHeaders },
    { name: "Top Ventas", data: excelTop, headers: graphTopSalesHeaders },
  ];

  //------------------------------------------------

  // APIS
  //------------------------------------------------
  const graphAPI = useMemo(() => new GraphsAPI(), []);
  const adminAPI = useMemo(() => new AdminAPI(), []);

  //------------------------------------------------

  //DATA REQUEST
  //------------------------------------------------

  const RequestData = () => {
    GetDataEntries();
    GetMoreInventory();
    GetLessInventory();
    GetDataTopSales();
    GetDataSales();
  };

  const GetClients = async () => {
    let data = new FormData();

    const response = await adminAPI.clientsListCleanedEP(data);
    if (response.status == 200) {
      setClients([{ id: 0, razon_social: "Todos" }, ...response.data.clients]);
    } else {
      console.log(response);
    }
  };

  const GetDataEntries = async () => {
    setLoaderEntries(true);

    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await graphAPI.entriesGraphEP(data);
    if (response.status == 200) {
      let aux = response.data.entries;
      let aux2 = [...response.data.entries];
      let aux3 = aux2.map((element, i) => {
        return [element[0], element[1], styles[0][i + 1][2]];
      });

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          type: el.type,
          total: el.total.toString() /*Excel muestra data en 0 si es string*/,
        }));
        setDataEntries([
          [["Entradas", "Cantidad", { role: "style" }], ...aux3],
        ]);
        let aux4 = aux.map((el) => el[1]).reduce((a, b) => a + b);
        setTotalEntries(aux4);
        setExcelEntries(auxExcel);
      } else {
        setDataEntries(dummyDataEntries);
        setExcelEntries(dummyExcelEntries);
        setTotalEntries(0);
      }

      setLoaderEntries(false);
    } else {
      // console.log(response);
      setDataEntries(dummyDataEntries);
      setExcelEntries(dummyExcelEntries);
    }
  };

  const GetMoreInventory = async () => {
    setLoaderMore(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await graphAPI.moreStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;

      if (aux.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          product: el.product,
          description: el.description,
          stock: el.stock.toString(),
        }));
        setDataMoreInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...aux,
        ]);
        setDataMoreFullInventory([
          /*MUESTRA CADENA COMPLETA AL ABRIR EL MODAL */
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelMore(auxExcel);
      } else {
        setDataMoreInventory(dummyDataMore);
        setDataMoreFullInventory(dummyDataMore);
        setExcelMore(dummyExcelMore);
      }
      setLoaderMore(false);
    } else {
      // console.log(response);
      setDataMoreInventory(dummyDataMore);
      setExcelMore(dummyExcelMore);
    }
  };

  const GetLessInventory = async () => {
    setLoaderLess(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await graphAPI.lessStockEP(data);
    if (response.status == 200) {
      const aux = response.data.inventory.map((subarr) => [
        subarr[0],
        subarr[1],
        subarr[2].substring(0, 20),
      ]);
      let auxFull = response.data.inventory;

      const auxExcel = response.data.excel.map((el) => ({
        product: el.product,
        description: el.description,
        stock: el.stock.toString(),
      }));
      if (aux.length != 0) {
        setDataLessInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...aux,
        ]);
        setDataLessFullInventory([
          ["Producto", "Stock", { role: "annotation" }],
          ...auxFull,
        ]);
        setExcelLess(auxExcel);
      } else {
        setDataLessInventory(dummyDataLess);
        setExcelLess(dummyExcelLess);
        setDataLessFullInventory(dummyDataLess);
      }
      setLoaderLess(false);
    } else {
      // console.log(response);
      setDataLessInventory(dummyDataLess);
      setExcelLess(dummyExcelLess);
    }
  };

  //FORMAT DATE TO DD/MM/YYYY
  //------------------------------------------------

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, 0);
  };

  const formatDate = (date) => {
    /* Necessary to render the date in "dd/mm/yyyy" format, since the library does not have a property to do it by default */
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };
  //------------------------------------------------

  const GetDataSales = async () => {
    setLoaderSales(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await graphAPI.salesAmountPerDayEP(data);
    if (response.status == 200) {
      const sales = response.data.sales;

      if (sales.length != 0) {
        const auxExcel = response.data.excel.map((el) => ({
          date: el.date,
          amount: el.amount.toString(),
        }));
        const arrWithDate = [...sales].map((el) => [
          formatDate(new Date(...el[0])),
          el[1],
        ]);
        setDataSales([["Día", "Monto $"], ...arrWithDate]);
        setExcelSales(auxExcel);
      } else {
        const date = new Date();
        let dummyData = [];

        for (let i = 1; i <= date.getDate(); i++) {
          dummyData.push([new Date(date.getFullYear(), date.getMonth(), i), 0]);
        }
        setDataSales([["Día", "Monto $"], ...dummyData]);
        setExcelSales(dummyExcelSales);
      }
      setLoaderSales(false);
    } else {
      // console.log(response);
      const date = new Date();
      let dummyData = [];
      for (let i = 1; i <= date.getDate(); i++) {
        dummyData.push([
          new Date(date.getFullYear(), date.getMonth(), i),
          0,
        ]); /* Render each day up to the current one with an amount of 0 */
      }
      setDataSales([["Día", "Monto"], ...dummyData]);
      setExcelSales(dummyExcelSales);
    }
  };

  const GetDataTopSales = async () => {
    setLoaderTop(true);
    let data = new FormData();
    data.append("startDate", getDayjsFormatted(startFilterDate));
    data.append("endDate", getDayjsFormatted(endFilterDate));

    if (client != "Todos") {
      const auxClient = clients.find((el) => el.razon_social == client);
      data.append("client", auxClient.id);
    }

    const response = await graphAPI.topSalesEP(data);
    if (response.status == 200) {
      const aux = response.data.more_sales;
      if (aux.length != 0) {
        const auxExcel = response.data.more_sales_excel.map((el) => ({
          decripcion: el.decripcion,
          sales: el.sales,
          total: el.total.toString(),
        }));

        //  sales formatting:
        aux.forEach((el) => {
          const sales = el[2].replace("$", "");
          el[2] = `$ ${new Intl.NumberFormat("es-MX").format(sales)}`;
        });

        setDataTopSales([
          ["Producto", "Piezas", { role: "annotation" }],
          ...aux,
        ]);
        setExcelTop(auxExcel);
      } else {
        setDataTopSales(dummyDataTopSales);
        setExcelTop(dummyTopExcel);
      }
      setLoaderTop(false);
    } else {
      // console.log(response);
      setDataTopSales(dummyDataTopSales);
      setExcelTop(dummyTopExcel);
    }
  };

  //------------------------------------------------

  //HANDLERS
  //------------------------------------------------

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleCloseFilterModal = () => setAnchorEl(null);

  const handleCloseModal = () => {
    setAnchorEl(null);
    settingFilters(
      prevData.client,
      prevData.startFilterDate,
      prevData.endFilterDate
    );
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  const handleChangeClient = (e) => {
    setClient(e.target.value);
  };

  const handleFilter = () => {
    if (endFilterDate < startFilterDate) {
      setErrors({ error: true });
    } else {
      handleCloseFilterModal();
      setErrors({
        error: false,
        errorNullStart: false,
        errorNullEnd: false,
      });
      RequestData();

      setPrevData({
        client: client,
        startFilterDate: startFilterDate,
        endFilterDate: endFilterDate,
      });
    }
  };

  const handleResetFilter = async () => {
    settingFilters("Todos", firstDay, today);
    setErrors({
      error: false,
      errorNullStart: false,
      errorNullEnd: false,
    });
  };

  //------------------------------------------------

  //FILTERS SETTING
  //------------------------------------------------

  const settingFilters = (client, firstDay, endDay) => {
    setClient(client);
    setStartFilterDate(firstDay);
    setEndFilterDate(endDay);
  };

  //------------------------------------------------

  return (
    <>
      <div className="w-100 px-3">
        <div
          className={`${
            phone ? "mt-1" : "mt-2"
          } d-flex justify-content-between`}
        >
          <DownloadButton
            client={client}
            text={""}
            htmlElement={htmlElement}
            fileName={"Reporte General"}
            excel={true}
            modal={false}
            info={info}
            sheetName={`Gráficas Reporte General`}
          />
          <div>
            <Tooltip title="Filtrar" arrow placement="left">
              <IconButton onClick={handleClick}>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={invisible}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "lightgreen",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                    },
                  }}
                >
                  <img src={FilterIcon} width={"40px"} alt="Filtro" />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
            ref={htmlElement}
          >
            <EntriesGraph
              client={client}
              type={0}
              data={dataEntries}
              total={totalEntries}
              isLoading={loaderEntries}
            />
            <MoreExistancesGraph
              client={client}
              data={dataMoreInventory}
              dataFull={dataMoreFullInventory}
              isLoading={loaderMore}
            />
            <LessExistancesGraph
              client={client}
              data={dataLessInventory}
              dataFull={dataLessFullInventory}
              isLoading={loaderLess}
            />
            <SalesGraph
              client={client}
              data={dataSales}
              isLoading={loaderSales}
            />
            <TopSalesGraph
              client={client}
              data={dataTopSales}
              isLoading={loaderTop}
            />
            <div className="d-none" id="client-data-lol"></div>
          </Grid>
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseFilterModal}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ModalFilters
          setErrors={setErrors}
          handleClose={handleCloseModal}
          dates={{
            setEnd: setEndFilterDate,
            end: endFilterDate,
            setStart: setStartFilterDate,
            start: startFilterDate,
          }}
          fields={[
            {
              array: clients,
              prop: "razon_social",
              type: "Cliente",
              value: client,
              handleOnChange: handleChangeClient,
            },
          ]}
          errors={errors}
          onClickFilter={handleFilter}
          onClickResetFilter={handleResetFilter}
        />
      </Popover>
    </>
  );
};
