import { useState } from 'react';
import './App.css';
import IntegrationNotistackClient, { Layout } from './components/Layout'

function App() {
  const [variant, setVariant] = useState("");

  return (
    <IntegrationNotistackClient variant={variant} setVariant={setVariant} />
  );
}

export default App;
