export const productsHeaders = [
  { label: "Nombre", key: "name", excel: true },
  { label: "Descripción", key: "descripcion", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Código de barras", key: "codigo_barras", excel: true },
  { label: "Precio", key: "precio_por_unidad", excel: true },
  { label: "Costo", key: "standard_price", excel: true },
  { label: "Estatus", key: "activo", excel: true },
  { label: "Fecha de registro", key: "fecha_creacion", excel: true },
  { label: "Categoría Odoo", key: "categoria", excel: true },
  { label: "Subcategoria", key: "subcategory", excel: true },
  { label: "Categoria de Uber", key: "clase_articlo", excel: true },
  { label: "Peso", key: "peso", excel: true },
  { label: "Alto", key: "altura", excel: true },
  { label: "Ancho", key: "ancho", excel: true },
  { label: "Profundidad", key: "profundidad", excel: true },
  { label: "Puede servir solo", key: "can_serve_alone", excel: true },
  { label: "Es alcoholico", key: "is_alcoholic", excel: true },
  { label: "Es vegetariano", key: "is_vegetarian", excel: true },
  { label: "Perecedero", key: "perishable", excel: true },
  { label: "Dias de expiracion", key: "expiration_time", excel: true },
  { label: "Consumir antes de", key: "use_time", excel: true },
  { label: "Tiempo de remocion", key: "removal_time", excel: true },
  { label: "Dias de alerta", key: "alert_time", excel: true },
];

export const productsAdminHeaders = [
  { label: "Cliente", key: "cliente", excel: true },
  { label: "Descripción", key: "descripcion", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Código de barras", key: "codigo_barras", excel: true },
  { label: "Precio", key: "precio_por_unidad", excel: true },
  { label: "Costo", key: "standard_price", excel: true },
  { label: "Estatus", key: "activo", excel: true },
  { label: "Fecha de registro", key: "fecha_creacion", excel: true },
  { label: "Categoría Odoo", key: "categoria", excel: true },
  { label: "Subcategoria", key: "subcategory", excel: true },
  { label: "Categoria de Uber", key: "clase_articlo", excel: true },
  { label: "Peso", key: "peso", excel: true },
  { label: "Alto", key: "altura", excel: true },
  { label: "Ancho", key: "ancho", excel: true },
  { label: "Profundidad", key: "profundidad", excel: true },
  { label: "Puede servir solo", key: "can_serve_alone", excel: true },
  { label: "Es alcoholico", key: "is_alcoholic", excel: true },
  { label: "Es vegetariano", key: "is_vegetarian", excel: true },
  { label: "Perecedero", key: "perishable", excel: true },
  { label: "Dias de expiracion", key: "expiration_time", excel: true },
  { label: "Consumir antes de", key: "use_time", excel: true },
  { label: "Tiempo de remocion", key: "removal_time", excel: true },
  { label: "Dias de alerta", key: "alert_time", excel: true },
];

export const inventoryHeaders = [
  { label: "Descripción", key: "descripcion", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Código de barras", key: "codigo_barras", excel: true },
  { label: "Stock", key: "stock", excel: true },
  { label: "Comprometido", key: "comprometido", excel: true },
  { label: "Solicitado", key: "solicitado", excel: true },
  { label: "Disponible", key: "disponible", excel: true },
  { label: "Merma", key: "merma", excel: true },
  { label: "Categoría", key: "categoria", excel: true },
  { label: "Sucursal", key: "sucursal", excel: true },
  { label: "Fecha", key: "fecha_creacion", excel: true },
];

export const inventoryHeadersAdmin = [
  { label: "Cliente", key: "cliente", excel: true },
  { label: "Descripción", key: "descripcion", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Código de barras", key: "codigo_barras", excel: true },
  { label: "Stock", key: "stock", excel: true },
  { label: "Comprometido", key: "comprometido", excel: true },
  { label: "Solicitado", key: "solicitado", excel: true },
  { label: "Disponible", key: "disponible", excel: true },
  { label: "Merma", key: "merma", excel: true },
  { label: "Categoría", key: "categoria", excel: true },
  { label: "Almacén", key: "sucursal", excel: true },
  { label: "Fecha", key: "fecha_creacion", excel: true },
];

export const entriesHeaders = [
  { label: "Folio", key: "folio" },
  { label: "Producto actualizado", key: "product", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Piezas añadidas", key: "pieces", excel: true },
  { label: "Fecha de creación", key: "creation_date", excel: true },
  { label: "Estatus", key: "status", excel: true },
  { label: "Almacén", key: "almacen", excel: true },
  { label: "Llegada", key: "expected_date", excel: true },
  { label: "Caducidad", key: "expiration_date", excel: true },
];
export const entriesHeadersAdmin = [
  { label: "Cliente", key: "cliente", excel: true },
  { label: "Folio", key: "folio", excel: true },
  { label: "Producto actualizado", key: "product", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Piezas añadidas", key: "pieces", excel: true },
  { label: "Fecha de creación", key: "creation_date", excel: true },
  { label: "Estatus", key: "status", excel: true },
  { label: "Almacén", key: "almacen", excel: true },
  { label: "Llegada", key: "expected_date", excel: true },
  { label: "Caducidad", key: "expiration_date", excel: true },
];

export const clientsHeaders = [
  { label: "Razón social", key: "razon_social", excel: true },
  { label: "RFC", key: "rfc", excel: true },
  { label: "Régimen fiscal", key: "regimen_fiscal", excel: true },
  { label: "Nombres", key: "nombre", excel: true },
  { label: "Apellidos", key: "apellidos", excel: true },
  { label: "Email", key: "email", excel: true },
  { label: "Teléfono celular", key: "telefono", excel: true },
  { label: "Teléfono oficina", key: "telefono_oficina", excel: true },
  { label: "Sitio web", key: "sitio_web", excel: true },
  { label: "Calle", key: "calle", excel: true },
  { label: "Colonia", key: "colonia", excel: true },
  { label: "C.P", key: "codigo_postal", excel: true },
  { label: "Ciudad", key: "ciudad", excel: true },
  { label: "Fecha", key: "fecha_registro", excel: true },
];

export const transferHeaders = [
  { label: "Folio", key: "id", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Producto", key: "product", excel: true },
  { label: "Origen", key: "departure", excel: true },
  { label: "Destino", key: "arrive", excel: true },
  { label: "Cantidad", key: "amount", excel: true },
  { label: "Estatus", key: "status", excel: true },
  { label: "Fecha Salida", key: "departure_date", excel: true },
  { label: "Fecha Recepción", key: "arrive_date", excel: true },
];

export const transferHeadersAdmin = [
  { label: "Cliente", key: "client", excel: true },
  { label: "Folio", key: "id", excel: true },
  { label: "SKU", key: "sku", excel: true },
  { label: "Producto", key: "product", excel: true },
  { label: "Origen", key: "departure", excel: true },
  { label: "Destino", key: "arrive", excel: true },
  { label: "Cantidad", key: "amount", excel: true },
  { label: "Estatus", key: "status", excel: true },
  { label: "Fecha Salida", key: "departure_date", excel: true },
  { label: "Fecha Recepción", key: "arrive_date", excel: true },
];

export const salesHeaders = [
  { label: "Código de pedido", key: "codigo_pedido", excel: true },
  { label: "Canal", key: "eccommerce", excel: true },
  { label: "Estatus envío", key: "estatus_envio", excel: true },
  { label: "Número de guía", key: "u_NumeroGuia", excel: true },
  { label: "Monto", key: "monto_pedido", excel: true },
  { label: "Método de pago", key: "metodo_pago", excel: true },
  { label: "Almacén", key: "almacen_asignado", excel: true },
  { label: "Fecha", key: "fecha_pedido", excel: true },
  { label: "Nombre cliente", key: "nombre_cliente", excel: true },
  { label: "Apellido cliente", key: "apellido_cliente", excel: true },
  { label: "Email cliente", key: "email_cliente", excel: true },
  { label: "Teléfono cliente", key: "telefono_cliente", excel: true },
  { label: "Dirección cliente", key: "direccion", excel: true },
];

export const salesHeadersAdmin = [
  { label: "Código de cliente", key: "cardCode", excel: true },
  { label: "Código de pedido", key: "codigo_pedido", excel: true },
  { label: "Canal", key: "eccommerce", excel: true },
  { label: "Estatus envío", key: "estatus_envio", excel: true },
  { label: "Número de guía", key: "u_NumeroGuia", excel: true },
  { label: "Monto", key: "monto_pedido", excel: true },
  { label: "Método de pago", key: "metodo_pago", excel: true },
  { label: "Almacén", key: "almacen_asignado", excel: true },
  { label: "Fecha", key: "fecha_pedido", excel: true },
  { label: "Nombre cliente", key: "nombre_cliente", excel: true },
  { label: "Apellido cliente", key: "apellido_cliente", excel: true },
  { label: "Email cliente", key: "email_cliente", excel: true },
  { label: "Teléfono cliente", key: "telefono_cliente", excel: true },
  { label: "Dirección cliente", key: "direccion", excel: true },
];

export const usersHeaders = [
  { label: "Nombre", key: "nombre", excel: true },
  { label: "Apellido", key: "apellidos", excel: true },
  { label: "Teléfono", key: "telefono", excel: true },
  { label: "Email", key: "email", excel: true },
  { label: "Rol", key: "rol", excel: true },
  { label: "Puesto", key: "employee_position", excel: true },
  { label: "Fecha", key: "fecha_registro", excel: true },
];

export const HFMActiveSales = [
  {
    label: "Código pedido",
    key: "folio",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Envío",
    key: "order_type",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Embalaje",
    key: "box",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Picker",
    key: "picker",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Sucursal",
    key: "warehouse",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Artículos",
    key: "products",
    variant: "text",
    width: "auto",
    excel: false,
  },
  {
    label: "Canal",
    key: "channel",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Cliente",
    key: "client",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Estatus",
    key: "status",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "F. Creada",
    key: "date_created",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "F. Aceptada",
    key: "date_accepted",
    variant: "text",
    width: "auto",
    excel: true,
  },
];

export const HFMHistoricalSales = [
  {
    label: "Código pedido",
    key: "folio",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Envío",
    key: "order_type",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Picker",
    key: "picker",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Sucursal",
    key: "warehouse",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Artículos",
    key: "products",
    variant: "text",
    width: "auto",
    excel: false,
  },
  {
    label: "Canal",
    key: "channel",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Cliente",
    key: "client",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Estatus venta",
    key: "status",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Comentarios",
    key: "comments",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Última milla",
    key: "lastMile",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Estatus última milla",
    key: "statusLastMile",
    variant: "text",
    width: "auto",
    excel: true,
  },

  {
    label: "F. Creada",
    key: "date_created",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "F. Aceptada",
    key: "date_accepted",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "F. Finalizada",
    key: "date_finished",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "T. Picking",
    key: "time_completed",
    variant: "text",
    width: "auto",
    excel: true,
  },
];

export const pickersHeaders = [
  {
    label: "Acciones",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Foto",
    variant: "circular",
    width: 50,
    excel: true,
  },
  {
    label: "Nombre",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Almacén",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Disponibilidad",
    variant: "circular",
    width: 30,
    excel: true,
  },
  {
    label: "Picking",
    variant: "circular",
    width: 30,
    excel: true,
  },
  {
    label: "Inicio turno",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Fin turno",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Ubicación",
    variant: "text",
    width: "auto",
    excel: true,
  },
  {
    label: "Contacto",
    variant: "circular",
    width: 30,
    excel: false,
  },
];

export const pickersCardHeaders = [
  {
    label: "Foto",
    variant: "circular",
    width: 70,
  },
  {
    variant: "text",
    width: "auto",
  },
  {
    variant: "text",
    width: "auto",
  },
  {
    variant: "text",
    width: "auto",
  },

  {
    variant: "text",
    width: "auto",
  },
  {
    variant: "text",
    width: "auto",
  },
  {
    variant: "text",
    width: "auto",
  },
];

export const B2BSales = [
  {
    label: "Acciones",
    variant: "text",
    width: "auto",
  },
  {
    label: "Código pedido",
    variant: "text",
    width: "auto",
  },
  {
    label: "Referencia interna",
    variant: "text",
    width: "auto",
  },
  {
    label: "Fecha registro",
    variant: "text",
    width: "auto",
  },
  {
    label: "Estatus",
    variant: "text",
    width: "auto",
  },
  {
    label: "Negocio",
    variant: "text",
    width: "auto",
  },
  /* {
    label: "Origen",
    variant: "text",
    width: "auto",
  }, */
  {
    label: "Destino",
    variant: "text",
    width: "auto",
  },
  ,
  {
    label: "Monto",
    variant: "text",
    width: "auto",
  },
];

export const B2BSalesAdmin = [
  {
    label: "Acciones",
    variant: "text",
    width: "auto",
  },
  {
    label: "Cliente",
    variant: "text",
    width: "auto",
  },
  {
    label: "Código pedido",
    variant: "text",
    width: "auto",
  },
  {
    label: "Referencia interna",
    variant: "text",
    width: "auto",
  },
  {
    label: "Fecha registro",
    variant: "text",
    width: "auto",
  },
  {
    label: "Estatus",
    variant: "text",
    width: "auto",
  },
  {
    label: "Negocio",
    variant: "text",
    width: "auto",
  },
  ,
  /* {
    label: "Origen",
    variant: "text",
    width: "auto",
  }, */
  {
    label: "Destino",
    variant: "text",
    width: "auto",
  },
  {
    label: "Monto",
    variant: "text",
    width: "auto",
  },
];

export const ExcelB2BSales = [
  {
    label: "Código pedido",
    key: "orderCode",
    excel: true,
  },
  {
    label: "Referencia interna",
    key: "reference",
    excel: true,
  },
  {
    label: "Fecha de registro",
    key: "creationDate",
    excel: true,
  },
  /* {
    label: "Fecha salida",
    key: "departureDate",
    excel: true,
  }, */
  {
    label: "Fecha llegada",
    key: "deliveryDate",
    excel: true,
  },
  {
    label: "Etatus",
    key: "status",
    excel: true,
  },
  {
    label: "Negocio",
    key: "business",
    excel: true,
  },
  ,
  /* {
    label: "Origen",
    key: "branch",
    excel: true,
  }, */
  {
    label: "Destino",
    key: "businessLocation",
    excel: true,
  },
  {
    label: "Monto",
    key: "amount",
    excel: true,
  },
  {
    label: "Método de pago",
    key: "paymentMethod",
    excel: true,
  },
];

export const ExcelB2BAdmin = [
  {
    label: "Cliente",
    key: "client",
    excel: true,
  },
  {
    label: "Código pedido",
    key: "orderCode",
    excel: true,
  },
  {
    label: "Referencia interna",
    key: "reference",
    excel: true,
  },
  {
    label: "Fecha de registro",
    key: "creationDate",
    excel: true,
  },
  /* {
    label: "Fecha salida",
    key: "departureDate",
    excel: true,
  }, */
  {
    label: "Fecha llegada",
    key: "deliveryDate",
    excel: true,
  },
  {
    label: "Estatus",
    key: "status",
    excel: true,
  },
  {
    label: "Negocio",
    key: "business",
    excel: true,
  },
  ,
  /* {
    label: "Origen",
    key: "branch",
    excel: true,
  }, */
  {
    label: "Destino",
    key: "businessLocation",
    excel: true,
  },
  {
    label: "Monto",
    key: "amount",
    excel: true,
  },
  {
    label: "Método de pago",
    key: "paymentMethod",
    excel: true,
  },
];

export const BusinessHeaders = [
  {
    label: "Acciones",
    variant: "text",
    width: "auto",
  },
  {
    label: "Negocio",
    variant: "text",
    width: "auto",
  },
  {
    label: "Tipo de régimen",
    variant: "text",
    width: "auto",
  },
  {
    label: "Estatus",
    variant: "text",
    width: "auto",
  },
  /* {
    label: "Última milla",
    variant: "text",
    width: "auto",
  }, */
];

export const BusinessHeadersAdmin = [
  {
    label: "Cliente",
    variant: "text",
    width: "auto",
  },
  {
    label: "Negocio",
    variant: "text",
    width: "auto",
  },
  {
    label: "Tipo de régimen",
    variant: "text",
    width: "auto",
  },
  {
    label: "Estatus",
    variant: "text",
    width: "auto",
  },
  /* {
    label: "Última milla",
    variant: "text",
    width: "auto",
  }, */
];

export const LocationsExcelHeaders = [
  {
    label: "Negocio",
    key: "business_name",
  },
  {
    label: "Alias",
    key: "alias",
  },
  {
    label: "Fecha de alta",
    key: "date_created",
  },
  {
    label: "País",
    key: "country",
  },
  {
    label: "Estado",
    key: "state",
  },
  {
    label: "Municipio",
    key: "municipality",
  },
  {
    label: "Colonia",
    key: "neighborhood",
  },
  {
    label: "Calle",
    key: "street",
  },
  {
    label: "Número",
    key: "number",
  },
  {
    label: "Horario de apertura",
    key: "open",
  },
  {
    label: "Horario de cierre",
    key: "close",
  },
  {
    label: "Nombre de contacto",
    key: "contact_name",
  },
  {
    label: "Teléfono de contacto",
    key: "cell_phone",
  },
  {
    label: "Email de contacto",
    key: "email",
  },
];

export const LocationsExcelAdmin = [
  {
    label: "Cliente",
    key: "client",
  },
  {
    label: "Negocio",
    key: "business_name",
  },
  {
    label: "Alias",
    key: "alias",
  },
  {
    label: "Fecha de alta",
    key: "date_created",
  },
  {
    label: "País",
    key: "country",
  },
  {
    label: "Estado",
    key: "state",
  },
  {
    label: "Municipio",
    key: "municipality",
  },
  {
    label: "Colonia",
    key: "neighborhood",
  },
  {
    label: "Calle",
    key: "street",
  },
  {
    label: "Número",
    key: "number",
  },
  {
    label: "Horario de apertura",
    key: "open",
  },
  {
    label: "Horario de cierre",
    key: "close",
  },
  {
    label: "Nombre de contacto",
    key: "contact_name",
  },
  {
    label: "Teléfono de contacto",
    key: "cell_phone",
  },
  {
    label: "Email de contacto",
    key: "email",
  },
];

export const BusinessExcelHeaders = [
  {
    label: "Negocio",
    key: "business",
  },
  {
    label: "Fecha alta",
    key: "date_created",
  },
  {
    label: "Estatus",
    key: "status",
  },
  {
    label: "Razón social",
    key: "business_name",
  },
  {
    label: "RFC",
    key: "rfc",
  },
  {
    label: "Representate legal",
    key: "legal_representative",
  },
  {
    label: "Régimen fiscal",
    key: "tax_regime",
  },
  {
    label: "Tipo de régimen",
    key: "regime_type",
  },
  {
    label: "Sitio web",
    key: "website",
  },
  {
    label: "Teléfono oficina",
    key: "office_phone",
  },
  {
    label: "Dirección oficina",
    key: "address",
  },
  {
    label: "Nombre de contacto",
    key: "contact_name",
  },
  {
    label: "Teléfono de contacto",
    key: "cell_phone_contact",
  },
  {
    label: "Email de contacto",
    key: "email_contact",
  },
];

export const BusinessExcelAdmin = [
  {
    label: "Cliente",
    key: "client",
  },
  {
    label: "Negocio",
    key: "business",
  },
  {
    label: "Fecha alta",
    key: "date_created",
  },
  {
    label: "Estatus",
    key: "status",
  },
  {
    label: "Razón social",
    key: "business_name",
  },
  {
    label: "RFC",
    key: "rfc",
  },
  {
    label: "Representate legal",
    key: "legal_representative",
  },
  {
    label: "Régimen fiscal",
    key: "tax_regime",
  },
  {
    label: "Tipo de régimen",
    key: "regime_type",
  },
  {
    label: "Sitio web",
    key: "website",
  },
  {
    label: "Teléfono oficina",
    key: "office_phone",
  },
  {
    label: "Dirección oficina",
    key: "address",
  },
  {
    label: "Nombre de contacto",
    key: "contact_name",
  },
  {
    label: "Teléfono de contacto",
    key: "cell_phone_contact",
  },
  {
    label: "Email de contacto",
    key: "email_contact",
  },
];

export const ProductSaleB2B = [
  {
    label: "SKU",
    key: "sku",
    excel: true,
  },
  {
    label: "Piezas",
    key: "quantity",
    excel: true,
  },
];

export const CategoriesHeaders = [
  {
    label: "id",
    key: "id",
  },
  {
    label: "categoría",
    key: "categoria",
  },
];

export const MassiveProductHeaders = [
  {
    label: "nombre",
    key: "name",
  },
  {
    label: "descripcion",
    key: "description",
  },
  {
    label: "imagen",
    key: "imagen",
  },
  {
    label: "sku",
    key: "sku",
  },
  {
    label: "categoria odoo",
    key: "categoria",
  },
  {
    label: "subcategoria",
    key: "subcategory",
  },
  {
    label: "codigo_barras",
    key: "codigo_barras",
  },
  {
    label: "precio",
    key: "precio",
  },
  {
    label: "costo",
    key: "standar_price",
  },
  {
    label: "peso (kg)",
    key: "peso",
  },
  {
    label: "alto (cm)",
    key: "alto",
  },
  {
    label: "ancho (cm)",
    key: "ancho",
  },
  {
    label: "profundidad (cm)",
    key: "profundidad",
  },
  {
    label: "puede servir solo",
    key: "can_serve_alone",
  },
  {
    label: "es alcoholico",
    key: "is_alcoholic",
  },
  {
    label: "vegetariano",
    key: "is_vegetarian",
  },
  {
    label: "perecedero",
    key: "perishable",
  },
  {
    label: "dias de expiracion",
    key: "expiration_time",
  },
  {
    label: "consumir antes de",
    key: "use_time",
  },
  {
    label: "tiempo de remocion",
    key: "removal_time",
  },
  {
    label: "dias de alerta",
    key: "alert_time",
  },
  {
    label: "categoria de uber",
    key: "clase_articlo",
  },
];

export const MassiveEditProductHeaders = [
  {
    label: "sku",
    key: "sku",
  },
  {
    label: "nombre",
    key: "name",
  },
  {
    label: "precio",
    key: "precio",
  },
  {
    label: "categoria de odoo",
    key: "clase_articlo",
  },
  {
    label: "subcategoria",
    key: "subcategoria"
  }
];
